import * as React from 'react';
import { IPopupRootProps } from '../PopupRoot.types';

const PopupRoot: React.FC<IPopupRootProps> = props => {
  const { children, id, styles } = props;

  const popupPage = children() as Array<React.ReactNode>;
  if (popupPage.length === 0) {
    return <div id={id} />;
  }

  return (
    <div id={id} className={styles.popupsRoot}>
      <div id="popups-wrapper" className={styles.popupsWrapper}>
        {popupPage}
      </div>
    </div>
  );
};

export default PopupRoot;
